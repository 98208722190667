<template>
  <v-data-table
    class="elevation-1"
    :items="data"
    :headers="headers"
    :loading="tableLoading"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            v-if="$state.user.is_superuser"
            small
            class="mr-2"
            @click="edit(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
        <span>Editar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            v-if="$state.user.is_superuser"
            small
            @click="remove(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <span>Remover</span>
      </v-tooltip>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Categoria</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="$state.user.is_superuser"
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              >Novo</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Categoria</span>
            </v-card-title>
            <ValidationObserver ref="form" v-slot="{ passes }">
              <v-form @submit.prevent="passes(save)" width="100%">
                <v-card-text>
                  <v-container>
                    <ValidationProvider
                      vid="nome"
                      name="Nome"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="Item.nome"
                        label="Nome"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="descricao"
                      name="Descrição"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-textarea
                        v-model="Item.descricao"
                        label="Descrição"
                        :error-messages="errors"
                      ></v-textarea>
                    </ValidationProvider>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                  <v-btn :loading="saving" color="darken-1" text @click="save"
                    >Salvar</v-btn
                  >
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { Categoria } from "@/api/core";
export default {
  name: "Categoria",
  data: () => ({
    data: [],
    headers: [
      {
        text: "Nome",
        value: "nome",
        align: "left",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
    Item: {},
    dialog: false,
    saving: false,
    tableLoading: false,
    errors: [],
  }),
  methods: {
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.Item = {};
      });
    },
    save() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.saving = true;
          const _then = () => {
            this.saving = false;
            this.close();
            this.fetch();
          };
          const _catch = (error) => {
            this.saving = false;
            this.errors = error.response.data;
            if (this.errors["non_field_errors"]) {
              this.$emitter.emit(
                "alert:error",
                this.errors["non_field_errors"][0]
              );
            } else {
              this.$emitter.emit("alert:error", this.errors);
            }
          };
          if (this.Item["id"]) {
            let id = this.Item["id"];

            Categoria.update(id, this.Item).then(_then).catch(_catch);
          } else {
            Categoria.post(this.Item).then(_then).catch(_catch);
          }
        }
      });
    },
    edit(item) {
      this.Item = Object.assign({}, item);
      this.dialog = true;
    },

    remove(item) {
      if (window.confirm("Deseja realmente excluir?")) {
        this.tableLoading = true;
        Categoria.remove(item["id"]).then(() => {
          this.tableLoading = false;
          this.fetch();
        });
      }
    },
    fetch() {
      this.tableLoading = true;
      Categoria.list().then((response) => {
        this.data = response.data;
        this.tableLoading = false;
      });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
